import React, { FC, useState, useEffect } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col, Image, Card, Form, Button, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ActionWrapper = styled.div`
  margin-top: 1rem;
`;

const Logos: FC = () => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, errors, setValue } = useForm();

  const [submitting, setSubmitting] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  useEffect(() => {
    register({ name: 'captchaToken' }, { required: true });
  });

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    await axios.post(`${process.env.GATSBY_API_URL!}/logos/download`, {
      ...values,
      locale: i18n.language,
    });
    setDownloaded(true);
    setSubmitting(false);
  };

  const onCaptchaSuccess = (token: string | null) => {
    setValue('captchaToken', token);
  };

  return (
    <Layout>
      <SEO article title="Logos" />
      <Container>
        <h1>{t('logos')}</h1>
        <Row>
          <Col lg={6}>
            <p>{t('page.logos.callout')}</p>
            <h3>{t('page.logos.how')}</h3>
            <p>{t('page.logos.how_description')}</p>
            <h3>{t('page.logos.when')}</h3>
            <p>{t('page.logos.when_description')}</p>
            <h3>{t('page.logos.where')}</h3>
            <p>{t('page.logos.where_description')}</p>
            <h3>{t('page.logos.download')}</h3>
            <Card>
              <Card.Body>
                {downloaded ? (
                  <Alert variant="info">{t('page.logos.download_hint')}</Alert>
                ) : (
                  <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                    <Form.Group>
                      <Form.Label>{t('page.logos.email')}</Form.Label>
                      <input
                        className="form-control"
                        name="email"
                        ref={register({
                          required: 'Required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t('error.invalid_email') as string,
                          },
                        })}
                      />
                      <Form.Text className="text-muted">{t('page.logos.email_hint')}</Form.Text>
                      {errors.email && errors.email.message && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          {errors.email.message}
                        </div>
                      )}
                    </Form.Group>
                    <ReCAPTCHA
                      sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY as string}
                      onChange={onCaptchaSuccess}
                      hl={i18n.language}
                    />
                    {errors.captchaToken && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {t('error.unverify_captcha')}
                      </div>
                    )}
                    <ActionWrapper>
                      <Button variant="primary" type="submit" disabled={submitting}>
                        {t('page.logos.submit')}
                      </Button>
                    </ActionWrapper>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <Image
              src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Logo_and_Wordmark_Monochrome_White.png`}
              fluid
            />
            <Image
              src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Logo_and_Wordmark_transparency.png`}
              fluid
            />
            <Image
              src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Logo_and_Wordmark_Monochrome_Black.png`}
              fluid
            />
            <Row noGutters className="mt-2">
              <Col>
                <Image src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Monochrome_White_Logo.png`} fluid />
              </Col>
              <Col>
                <Image src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Logo.png`} fluid />
              </Col>
              <Col>
                <Image src={`/logos/${i18n.language}/${i18n.language}_Makersflow_Monochrome_Black_Logo.png`} fluid />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Logos;
